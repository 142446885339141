@font-face {
  font-family: "Acumin Pro Wide";
  src: url("./assets/fonts/acuminprowide-semibold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Acumin Pro Wide";
  src: url("./assets/fonts/acuminprowide-medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Acumin Pro Wide";
  src: url("./assets/fonts/acuminprowide-regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Bradford LL";
  src: url('./assets/fonts/BradfordLLWeb-Medium.woff2');
}

@import-normalize; /* bring in normalize.css styles */

body {
  margin: 0;
  background: #E5E5E5;
  font-family: Acumin Pro Wide;
  font-weight: 600;
  font-style: normal;
  overscroll-behavior-y: contain;
}
